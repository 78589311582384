import React from 'react';

import '../../assets/sass/main.scss';

const Footer = () => (
  <footer id="footer" className="wrapper style1-alt">
    <div className="inner">
      <ul className="menu">
        <li>&copy; BetaStuff Inc. </li>
        <li>
          Made with ❤️ in NYC
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
