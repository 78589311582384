import React from 'react';

import pic4 from '../images/pic04.jpg';

import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';


const SecondPage = () => (
  <Layout>
    <Header />

    <div id="wrapper">
      <section id="main" className="wrapper">
        <div className="inner">
          <h1 className="major">Under Construction</h1>
          <span className="image fit">
            <img src={pic4} alt="" />
          </span>
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default SecondPage;
